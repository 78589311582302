import { VStack } from "@chakra-ui/react"
import React from "react"
import { Authenticated2 } from "../../components/auth/Authenticated2"
import { BodyWrapper } from "../../components/layout/BodyWrapper"
import { Layout } from "../../components/layout/Layout"
import { LoadingPage } from "../../components/layout/LoadingPage"
import { PageHeading } from "../../components/layout/PageHeading"
import { PageTitle } from "../../components/typing-test/PageTitle"

function LoggedIn() {
  return (
    <Layout>
      <BodyWrapper>
        <PageHeading>Preferences</PageHeading>
        <VStack spacing={6} align="stretch">
          <ul>
            <li>Vocabulary size</li>
            <li>Timer length</li>
            <li>Faded timer</li>
            <li>Show mistakes</li>
            <li>Smooth cursor movement</li>
          </ul>
        </VStack>
      </BodyWrapper>
    </Layout>
  )
}

export default function Prefs() {
  return (
    <Layout>
      <PageTitle>Preferences</PageTitle>
      <Authenticated2 loading={<LoadingPage />} auth={<LoggedIn />} />
    </Layout>
  )
}
